import locale from '../../i18n/pt-BR.json'
import logo from '../../assets/img/logo.png'
import '../../assets/css/menu.css'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function Menu(props: any) {

    const [activeLink, setActiveLink] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const savedActiveLink = localStorage.getItem('btnActive');
        if (savedActiveLink) {
            setActiveLink(savedActiveLink);
        }
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        const href = event.currentTarget.getAttribute('href')
        localStorage.setItem('btnActive', `${href}`)
        navigate(`${href}`)
    };

    return (
        <>
            <nav className="navbar bg-dark border-bottom border-body navbar-expand-lg navbar-expand-md fixed-top" data-bs-theme="dark">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo} alt="" className='logoMenu' />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {locale.menu.service.title.toUpperCase()}
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">{locale.menu.service.subItem['espiralWire-o']}</a></li>
                                    <li><a className="dropdown-item" href="#">{locale.menu.service.subItem.furacao}</a></li>
                                    <li><a className="dropdown-item" href="#">{locale.menu.service.subItem.intercalacao}</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${activeLink === '/' ? 'active' : ''}`} aria-current="page" href="/" onClick={handleClick} >
                                    {locale.menu.home.toUpperCase()}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${activeLink === '/contato' ? 'active' : ''}`} href="/contato" onClick={handleClick}>
                                    {locale.menu.contato.toUpperCase()}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${activeLink === '/sobre-nos' ? 'active' : ''}`} href="/sobre-nos"onClick={handleClick}>
                                    {locale.menu.sobreNos.toUpperCase()}
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="risco"></div> {/* Adiciona o risco correndo */}
                </div>
            </nav>
        </>
    )
}