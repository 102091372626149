import Menu from "../global/Menu";
import '../../assets/css/global.css'
import locale from '../../i18n/pt-BR.json'
import CardContato from "../global/CardContato";
import { useState } from "react";

export default function Contato(props: any) {

    const [formValues, setFormValues] = useState({
        nome: '',
        email: '',
        setor: '',
        telefone: '',
        descricao: ''
    })

    const [errors, setErrors] = useState({
        nome: '',
        email: '',
        setor: '',
        telefone: '',
        descricao: ''
    })

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: '',
        });
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            console.log(formValues); // Aqui você pode enviar os dados ou processá-los
        }
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = { nome: '', email: '', setor: '', telefone: '', descricao: '' };

        if (!formValues.nome.trim()) {
            newErrors.nome = '* Nome completo é obrigatório';
            valid = false;
        }
        if (!formValues.email.trim()) {
            newErrors.email = '* E-mail é obrigatório';
            valid = false;
        }
        if (!formValues.setor.trim()) {
            newErrors.setor = '* Setor é obrigatório';
            valid = false;
        }
        if (!formValues.telefone.trim()) {
            newErrors.telefone = '* Telefone/WhatsApp é obrigatório';
            valid = false;
        }
        if (!formValues.descricao.trim()) {
            newErrors.descricao = '* Descrição/Orçamento é obrigatório';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    }

    return (
        <>
            <Menu />
            <div className="container-fluid margem">
                <h1 className="text-center">{locale.menu.contato}</h1>
                <form onSubmit={handleSubmit}>
                    <CardContato formValues={formValues} onInputChange={handleInputChange} errors={errors} />
                </form>
            </div>
        </>
    )
}