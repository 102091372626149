import Menu from "../global/Menu"
import '../../assets/css/global.css'
import locale from '../../i18n/pt-BR.json'

export default function Home(props: any) {
  return (

    <>
      <Menu />
      
      <div className="container-fluid margem">
        <h1 className="text-center">{locale.menu.home}</h1>

      </div>
    </>
  )
}