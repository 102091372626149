import { useEffect, useRef } from "react"
import locale from '../../i18n/pt-BR.json'
import logo from '../../assets/img/logo2.png'
export default function ModalHistoria() {
    useEffect(() => {
        const btnModal = document.getElementById('btnModal');
        if (btnModal) {
            btnModal.click();
        }
    }, []);
    return (
        <>
            <button type="button" id="btnModal" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalHistoria" />
            <div className="modal fade" id="modalHistoria" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">{locale.empresa.nome}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-4 col-lg-4 col-sm-6">
                                    <img src={logo} alt="logotipo espiral e manuseio acabamento graficos" width={'100%'} />
                                </div>
                                <div className="col-md-8 col-lg-8 col-sm-12">
                                    {locale.modal.modalHistoria}
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Saber mais ...</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}