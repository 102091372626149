
export default function CardContato({ formValues, onInputChange, errors }: { formValues: any, onInputChange: any, errors: any }) {

    return (
        <>
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-md-12 col-sm-12 col-lg-8">
                    <div className="card bg-dark" style={{ color: "white" }}>
                        <div className="card-body">
                            <h5 className="card-title">Formulário</h5>
                            <h6 className="card-subtitle mb-2">Dúvidas/Orçamentos</h6><hr />
                            <div className="row">
                                {
                                    //INPUTS CONTATO
                                }
                                <div className="col-md-6 col-sm-12 col-lg-6">
                                    {errors.nome && <small className="text-danger">{errors.nome}</small>}
                                    <div className="form-floating mb-3">
                                        <input type="text" value={formValues.nome} name="nome"
                                            onChange={onInputChange} className="form-control" />
                                        <label>Nome completo</label>
                                    </div>

                                </div>
                                <div className="col-md-6 col-sm-12 col-lg-6">
                                    {errors.email && <small className="text-danger">{errors.email}</small>}
                                    <div className="form-floating mb-3">
                                        <input type="email" value={formValues.email} name="email"
                                            onChange={onInputChange} className="form-control" />
                                        <label>E-mail</label>
                                    </div>

                                </div>
                                <div className="col-12">
                                    {errors.setor && <small className="text-danger">{errors.setor}</small>}
                                    <div className="form-floating mb-3">
                                        <select className="form-select" value={formValues.setor} name="setor"
                                            onChange={onInputChange} id="floatingSelect" aria-label="Seleção setor resposavel">
                                            <option selected>---</option>
                                            <option value="Vendas">Vendas</option>
                                            <option value="Financeiro">Financeiro</option>
                                            <option value="Trabalhe Conosco">Trabalhe Conosco</option>
                                        </select>
                                        <label>Setor</label>
                                    </div>

                                </div>
                                <div className="col-md-6 col-sm-12 col-lg-6">
                                    {errors.telefone && <small className="text-danger">{errors.telefone}</small>}
                                    <div className="form-floating mb-3">
                                        <input type="number" value={formValues.telefone} name="telefone"
                                            onChange={onInputChange} className="form-control" />
                                        <label>Telefone/WhatsApp</label>
                                    </div>

                                </div>
                                <div className="col-md-6 col-sm-12 col-lg-6">
                                    {errors.descricao && <small className="text-danger">{errors.descricao}</small>}
                                    <div className="form-floating">
                                        <textarea className="form-control" value={formValues.descricao} name="descricao"
                                            onChange={onInputChange} />
                                        <label>Descrição/Orçamento</label>
                                    </div>

                                </div>
                                <div className="col-12">
                                    <input className="btn btn-primary" value="Submit" type="submit" /><hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}