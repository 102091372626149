import Menu from "../global/Menu";
import '../../assets/css/global.css'
import locale from '../../i18n/pt-BR.json'
import ModalHistoria from "../global/ModalHistoria";
import AccordionSobreNos from "../global/AccordionSobreNos";

export default function SobreNos() {

    return (
        <>
            <Menu />
            <ModalHistoria />
            <div className="container-fluid margem">
                <h1 className="text-center">{locale.menu.sobreNos}</h1><hr />
                <AccordionSobreNos/>

            </div>
        </>
    )
}